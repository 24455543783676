@import 'scss/base/colors.scss';

.details {
  display: flex;
  flex-direction: row;
}

.errorMessage {
  font-size: 12px;
  margin-bottom: 3px;
  color: $color-error;
  min-height: 15px;
  margin-left: 15px;
}

.error {
  border-color: $color-error;
}

.description {
  padding-left: 20px;
  margin: auto;
}
.menuContainer {
  position: relative;
}

.menu {
  border-radius: 8px;
  position: absolute;
  z-index: 999;
  left: 0;
  min-width: 150px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.label {
  font-size: 12px;
  margin-bottom: 3px;
  color: $color-hint;
  line-height: 30px;
  text-align: center;
  min-height: 15px;
  margin-left: 15px;
  background-color: red;
}

.menuTrigger {
  --colour: #ffffff;
  background: var(--colour);
  min-width: 150px;
  cursor: pointer;
  display: flex;
  border: 1px solid rgba(67, 66, 66, 0.5);
  border-radius: 20px;
  box-shadow: 2px 2px 8px -3px $color-main, -2px 0px 8px -3px $color-main;
  height: 33px;
  line-height: 33px;
  justify-content: center;
  color: $color-hint;
  font-size: 14px;
  text-align: center;
}
