//font sizes

$large-title-font: 2.5em;
$medium-font: 2em;
$small-font: 1.5em;

$small-page-title: 1.5em;
$page-title: 2em;
$details-title: 1.6em;
$details-info-title: 1em;
$details-info: 1em;

$font-dialog-title: 1.8em;
