// theme defaults
$color-main: #a8b98e;

// background color
$color-background: #ffffff;

// Table
$color-list-header: #888888;

//colours
$color-black: #000000;

$color-transparent-black: rgba(0, 0, 0, 0.8);

$color-hint: rgba(0, 0, 0, 0.5);

$color-error: #ff0000;

$color-calendar-divisor: #000000;
