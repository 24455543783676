.job {
  --height: 100px;
  --top: 0;
  position: absolute;
  top: var(--top);
  left: 0;
  width: 100%;
  background-color: white;
  height: var(--height);
  z-index: 100;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: hidden;

  padding: 2px;
  &:hover {
    z-index: 999;
    height: auto !important;
  }

  .mainInfo {
    min-height: var(--height);
    &:hover {
      background-color: rgba($color: #333333, $alpha: 0.1);
    }

    .paid {
      color: green;
    }
    .notPaid {
      color: red;
    }

    .firstLine {
      display: flex;
      flex-direction: row;
      padding-bottom: 3px;

      .actionIcons {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        cursor: pointer;

        .action {
          color: red;
        }

        .completedAction {
          color: green;
        }
      }

      .time {
        font-size: 17px;
        font-weight: bold;
      }
    }

    .client {
      padding: 3px 0px;
      font-size: 15px;
      min-width: 10px;
      cursor: pointer;
      &:hover {
        background-color: grey;
      }
    }

    .address {
      padding: 3px 0px;
      font-size: 15px;
      cursor: pointer;
      &:hover {
        background-color: grey;
      }
    }

    .field {
      font-weight: bold;
    }

    .subField {
      margin-left: 15px;
    }

    .status {
      cursor: pointer;
      font-weight: bold;
      padding: 1px;

      &:hover {
        background-color: grey;
      }
      &.unconfirmed {
        color: black;
      }
      &.confirmed {
        color: green;
      }
      &.inprogress {
        color: #ff6600;
      }
      &.needsrescheduling {
        color: #a60202;
      }
      &.completed {
        color: blue;
      }
    }

    .notes {
      margin-top: 7px;
      padding-top: 3px;
      border-top: 2px dashed black;
      .title {
        padding-bottom: 2px;
        font-weight: bold;
      }
    }
  }
}
