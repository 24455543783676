.data {
  padding-left: 20px;
  padding-right: 20px;
  word-wrap: break-word;
  word-break: normal;
}

.message {
  height: 100%;
  text-align: center;
}

.width {
  --width: 100%;
  width: var(--width);
}

.header {
  color: white;
  font-weight: bold;
}

.hover {
  &:hover {
    background-color: grey;
    border-radius: 10px;
    padding: 2px;
    cursor: pointer;
  }
}
