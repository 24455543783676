.container {
  border: 3px solid black;
  border-radius: 20px;
  box-shadow: 0 0 10px black;
  width: 100%;
  margin: 10px;
  position: relative;

  .title {
    line-height: 30px;
    font-size: 20px;
    border-bottom: 3px solid black;
    margin: 10px 10px;
    text-align: center;
    padding: 8px;
    background-color: black;
    color: white;
    font-weight: bold;
  }

  .info {
    margin: 10px 10px;
    display: flex;
    flex-direction: column;
  }

  .edit {
    position: absolute;
    color: white;
    top: 24px;
    right: 24px;
    font-size: 20px;
  }

  .loading {
    position: absolute;
    top: 22px;
    left: 16px;
  }
}
