@import 'scss/base/font.scss';

.container {
  display: flex;
  align-items: baseline;
  padding-top: 5px;

  .title {
    font-size: $details-info-title;
  }

  .info {
    padding-left: 10px;
    font-size: $details-info;
    white-space: normal;
    overflow-wrap: normal;
  }
}
