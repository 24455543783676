.item {
  display: flex;
  flex-direction: 1;
  height: 40px;
  line-height: 40px;
  padding: 0px 10px;
  width: 100%;

  .field {
    line-height: 40px;
    font-weight: bold;
    font-size: 18px;
    width: 150px;
    text-align: end;
  }
  .edit {
    padding-top: 2px;
    margin-left: 10px;
    width: 300px;
    display: flex;
    flex-direction: row;
    line-height: 40px;
  }
  .buttons {
    margin-left: 20px;
    padding-top: 2px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
