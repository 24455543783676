@import 'scss/base/colors.scss';
@import 'scss/base/font.scss';
@import 'scss/base/sizes.scss';

.container {
  background-color: $color-main;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .toolbar {
    .row {
      display: flex;
      margin-top: 1rem;
      padding: 0 1rem;
      gap: 10px;

      .jobTypeFilter {
        width: 200px;
      }

      .paidTypeFilter {
        width: 200px;
      }

      .completedFilter {
        width: 220px;
      }
    }
  }

  .listContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 100%;
  }
}
