@import 'scss/base/colors.scss';
.container {
  position: relative;
  width: 100%;

  input,
  label {
    display: block;
  }

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .info {
    border: 1px solid rgba(67, 66, 66, 0.5);
    border-radius: 20px;
    box-shadow: 2px 2px 8px -3px $color-main, -2px 0px 8px -3px $color-main;
    padding: 6px;
    background-size: 8%;
    padding-left: 15px;
    padding-right: 15px;
    height: 35px;
    width: 100%;

    ::placeholder {
      color: $color-hint;
      font-size: 8px;
    }
  }

  .label {
    font-size: 12px;
    color: $color-hint;
    height: 20px;
    margin-left: 15px;
  }

  .error {
    border-color: $color-error;
  }

  .errorMessage {
    font-size: 12px;
    margin-bottom: 3px;
    color: $color-error;
    min-height: 15px;
    margin-left: 15px;
  }
}
