.container {
  display: flex;
  flex-direction: column;

  .details {
    margin: 0;
    height: 100%;
    min-width: 300px;

    overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior: none;

    .address {
      text-align: center;
      margin: 10px 0px;
      font-weight: bold;
    }

    .section {
      margin: 10px 0px;
      border-top: 3px dashed grey;

      .title {
        font-weight: bold;
      }
      .notes {
        margin-top: 5px;
        .notesInfo {
          margin: 0px 8px;
          margin-top: 5px;
        }
      }
    }

    .button {
      margin: 5px;
    }

    .info {
      margin-left: 10px;

      .notes {
        white-space: normal;
        word-wrap: break-word;
      }
    }
  }

  .buttons {
    padding-bottom: 70px;
  }
}
