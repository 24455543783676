.child {
  margin-right: 10px;
  margin-top: auto;

  &:last-child {
    margin-right: 0px;
  }
}

.fullWidth {
  width: 100%;
}
