@import 'scss/base/colors.scss';

.item {
  height: 40px;
  align-content: center;
  line-height: 40px;
  padding-left: 20px;
  border-bottom: 2px solid black;

  .selected {
    font-weight: bold;
  }

  &:hover {
    background-color: $color-hint;
  }

  &:first-child {
    border-top: 2px solid black;
  }
}
