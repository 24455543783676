.mainDivision {
  background-color: black;
  color: white;
  position: relative;
  height: 50px;
  line-height: 30px;
  padding: 10px 0px;
  font-size: 25px;
  display: flex;

  .sectionTitle {
    width: 100%;
    text-align: center;
  }

  .clearButton {
    background-color: white;
    color: black;
    width: 100px;
    height: 30px;
    border-radius: 30px;
    position: absolute;
    margin-top: 10px;
    margin-right: 10px;
    font-size: 15px;
    border: 1px solid black;

    top: 0;
    right: 0;
  }
  .arrowControl {
    position: absolute;
    top: 5;
    left: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
