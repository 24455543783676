.row {
  border-bottom: 2px solid black;
  height: 60px;

  &:nth-child(odd) {
    background-color: #e5e5e5;
  }
  &:nth-child(even) {
    background-color: #f0f0f0;
  }
  &:hover {
    background-color: #ffffff;
    font-size: 17px;
  }
}

.fullHeight {
  height: 100%;
}
