@import 'scss/base/colors';

.container {
  width: 100%;
  height: 100%;
  background-color: $color-main;

  .details {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-items: center;
    width: 300px;
  }

  .submitButton {
    padding-top: 5px;
    width: 200px;
  }

  .error {
    color: red;
  }
}
