@import 'scss/base/colors.scss';
.errorMessage {
  font-size: 12px;
  margin-bottom: 3px;
  color: $color-error;
  min-height: 15px;
  margin-left: 15px;
}

.teamInfo {
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 3px dashed black;
  .team {
    font-size: 20px;
    font-weight: bold;
  }
  .members {
    font-size: 17px;
  }
}
