@import 'scss/base/colors.scss';

.table {
  height: 100%;
  border: 5px solid black;
  background-color: $color-background;

  table {
    height: 100%;
    border-collapse: collapse;

    tbody {
      display: block;
      height: calc(100% - 45px);
      overflow: auto;

      td {
        text-align: center;
      }
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
    }

    thead {
      background-color: #333333;

      tr {
        color: white;
        font-weight: bold;
        height: 50px;
        border-bottom: 3px solid black;

        td {
          border-right: 1px solid white;
          text-align: center;
        }
        :last-child {
          border-right: none;
        }
      }
    }

    th {
      position: sticky;
      top: 0;
      z-index: 999;
      background-color: grey;
      border-bottom: 5px solid black;
      height: 40px;
      text-align: start;
      padding-left: 20px;
    }
  }
}
