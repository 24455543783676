.column {
  flex-direction: row;
  padding-right: 25px;
  padding-left: 25px;
  border-right: 2px;
  border-color: black;
  border-right: 4px solid black;

  &:last-child {
    margin-right: 0px;
    border-right: 0px;
  }
}
