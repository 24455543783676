.container {
  min-width: 500px;
}
.membersTitle {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 25px;
  border-bottom: 2px dashed black;
  margin-top: 50px;
  margin-bottom: 10px;
}
.membersList {
  .member {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 30px;
    text-align: center;
    padding: 1px 10px;
    gap: 10px;
    margin: 2px;
    padding-right: 30px;
    border-radius: 30px;
    border: 2px solid black;
    margin-top: 4px;
  }

  i {
    position: relative;
    height: 30px;

    .icon {
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      pointer-events: painted;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
