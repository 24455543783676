.container {
  min-width: 500px;

  .autoFields {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .scheduleTypes {
    width: 200px;
  }

  .filterOptions {
    .row {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .dateSelector {
      margin-top: 10px;
    }
  }

  .results {
    .openDays {
      margin-top: 10px;
      padding-top: 20px;
      border-top: 3px solid black;

      .title {
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
      }
      .dateSummary {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
        border-top: 1px solid black;

        .btn {
          all: unset;
          margin-left: auto;
        }

        .date {
          font-weight: bold;
        }

        .teamItem {
          display: flex;
          flex-direction: row;
          margin-left: auto;
          width: 500px;
        }

        .data {
          margin-top: 10px;

          .sub {
            display: flex;
            flex-direction: row;
            gap: 20px;

            .itemTitle {
              width: 110px;
              text-align: right;
            }
          }
          margin-left: 30px;
        }
        .teamMembers {
          margin-left: 30px;
        }
      }
    }
  }
}

.customDropdown {
  display: flex;
  gap: 30px;
  flex-direction: row;
  justify-content: space-between;
  .address {
    font-weight: bold;
  }
}
