.container {
  width: 200px;
  background-color: white;

  .search {
    border-width: 0px 0px 2px 2px;
    border-style: solid;
    border-color: rgb(181, 181, 181);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;

    input {
      width: 100%;
      outline: none;
      border: none;
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 5px;
    }

    .iconDiv {
      vertical-align: middle;
      margin-top: auto;
      scale: 0.9;

      .icon {
        color: rgb(181, 181, 181);
      }
    }
  }
}
