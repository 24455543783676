.checkbox {
  margin-top: 30px;
}

.addButton {
  vertical-align: baseline;
  margin-top: auto;
}
.removeButton {
  vertical-align: baseline;
  margin-top: auto;
}

.form {
  display: flex;
  flex-direction: row;

  .dropdown {
    min-width: 200px;
  }
  .description {
    padding-bottom: 20px;
  }

  .titleElement {
    width: 100%;
    margin-top: 30px;
    text-align: center;
    padding-top: 7px;
    font-weight: bold;
    border-top: 2px dashed black;
  }

  .password {
    min-width: 400px;

    .passwordContent {
      display: flex;
      flex-direction: row;
      .passwords {
        margin: auto 0px;
        flex: 1;
      }
      .passwordCheck {
        font-size: 11px;
        .condition {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          padding: 0px;
          margin: auto 0px;

          .icon {
            height: 20px;
            width: 20px;
            transform: scale(0.7);
            margin: auto 0px;

            .valid {
              color: green;
            }
            .invalid {
              color: red;
            }
          }
          .info {
            margin: auto 0px;
          }
        }
      }
    }
  }

  .column {
    flex-direction: row;
    padding-right: 25px;
    padding-left: 25px;
    border-right: 2px;
    border-color: black;
    border-right: 4px solid black;

    &:last-child {
      margin-right: 0px;
      border-right: 0px;
    }
  }
}
