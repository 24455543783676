@import 'scss/base/colors.scss';

.container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $color-transparent-black;
  z-index: 999;

  &.visible {
    display: block;
  }

  &.gone {
    display: none;
  }

  .drawer {
    width: 215px;
    background-color: white;
    height: 100%;

    .icon {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 215px;

      height: 150px;

      img {
        position: fixed;
        width: 150px;
        height: 150px;
        left: 32px;
      }
    }
  }
}
