.jobItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  .description {
    font-weight: bold;
  }
}

.border {
  width: 100%;
  height: 3px;
  border-bottom: 3px dashed black;
}

.team {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.date {
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
}
