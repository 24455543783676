@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.body {
  --margin-bottom: 0px;
  --height: 0px;
  height: var(--height);
  border-bottom: 1px solid $color-calendar-divisor;
  border-right: $daily-calendar-title-column-divisor solid $color-calendar-divisor;

  .space {
    width: 100%;
    height: 100%;
    background-color: #999999;
  }
  .schedule {
    width: 100%;
    height: 100%;
    background-color: white;
  }
}
