@import 'scss/base/colors';

.container {
  width: 100%;
  height: 100%;
  background-color: white;

  .login {
    position: fixed;
    right: 50px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
  }

  /* Header Section */
  .header {
    background-color: #007bff;
    color: #fff;
    text-align: center;
    padding: 80px 0;
  }

  .nav {
    ul {
      list-style: none;

      display: inline;
      margin-right: 20px;
    }

    a {
      text-decoration: none;
      color: #fff;
      font-weight: bold;
    }
  }

  nav .ctaButton {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #fff;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
      color: #fff;
    }
  }

  /* Features Section */
  #features {
    padding: 80px 0;
    text-align: center;
  }

  .feature {
    margin-bottom: 30px;
    img {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
    }
  }

  .aboutUs {
    background-color: #f4f4f4;
    padding: 60px 0;
    text-align: center;
  }

  .contact {
    padding: 80px 0;
    text-align: center;
  }

  .address {
    font-style: normal;
    margin-top: 10px;
  }

  .footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    position: fixed;
    bottom: 0px;
    width: 100vw;

    a {
      color: #fff;
      text-decoration: none;
      margin: 0 10px;
    }
  }
}
