@import 'scss/base/font.scss';
@import 'scss/base/colors.scss';

.background {
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  max-width: 100%;
  background-color: $color-transparent-black;
  top: 0;
  left: 0;
  flex-wrap: wrap;
  overflow: hidden;

  .container {
    top: 50%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: $color-background;
    white-space: nowrap;
    height: 90%;
    margin: auto;
    border: 10px solid $color-main;
    border-radius: 20px;

    .loading {
      position: fixed;
      right: 10px;
      top: 10px;
    }

    .info {
      padding: 20px 20px 20px 20px;
      margin-bottom: 50px;
      overflow: scroll;
      height: 95%;

      .header {
        justify-content: space-between;
        display: flex;
        .titleBar {
          width: 100%;

          .title {
            font-weight: bold;
            font-size: $font-dialog-title;
            padding: 10px;
            text-align: center;
          }
        }
      }

      .children {
        padding-top: 10px;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      position: fixed;
      bottom: 0px;
      padding-right: 20px;

      width: 100%;
      height: 5%;
      padding: 0 30px;
      margin-bottom: 10px;
      background-color: $color-background;

      font-size: 20px;
      font-weight: bold;

      .confirmBtn {
      }
    }

    .fullScreen {
    }
  }
}

@media only screen and (max-width: 460px) {
  .background {
    .container {
      .info {
        .header {
          .title {
            font-size: $small-page-title;
          }
        }
      }
    }
  }
}
