.container {
  width: 300px;
  min-height: 50px;
  position: fixed;
  z-index: 999;
  bottom: 30px;
  left: 10px;
  border-radius: 20px;
  border: 3px solid white;
  background-color: white;
  font-weight: bold;

  .message {
    text-align: center;
    word-wrap: normal;
  }

  .success {
    background-color: rgba($color: #4bb543, $alpha: 0.6);
    border-radius: 20px;
    border: 3px solid green;
  }

  .error {
    background-color: rgba($color: red, $alpha: 0.6);
    border-radius: 20px;
    border: 3px solid red;
  }
}
