.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .content {
    text-align: center;
    img {
      width: 280px;
      height: 280px;
    }

    .confirmMessage {
      padding: 10px;
      font-size: 18px;
      font-weight: bold;
    }

    .warningMessage {
      padding: 10px;
    }

    .buttons {
      padding: 10px;
      padding-bottom: 40px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ensures that the content is centered vertically in the viewport */
}
