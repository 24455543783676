.container {
  width: 250px;
}

.warning {
  margin-top: 100px;
  width: 100%;
  white-space: normal;
  word-wrap: break-word;
}
