@import 'scss/base/colors.scss';

.item {
  --colour: #ffffff;
  border-bottom: 1px solid #dddddd;
  text-decoration: none;
  color: #333333;
  padding: 5px 5px;
  display: block;
  background-color: var(--colour);
  width: 100%;
  height: 30px;
  border-radius: 30px;

  &:first-child {
    color: $color-hint;
  }

  &:hover {
    border: 3px solid black;
  }
  a {
    text-decoration: none;
    color: #333333;
    padding: 5px 5px;
    display: block;
  }
}
