@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.daily {
  display: flex;
  height: 100%;
  overflow: scroll;
  background-color: black;
  padding: 10px;

  .times {
    border-bottom: 1px solid $color-calendar-divisor;
    width: 100px;
    min-width: 75px;

    border-right: $daily-calendar-title-column-divisor solid $color-calendar-divisor;

    .timeContainer {
      background-color: white;
      height: $daily-calendar-row-height;
      border-bottom: 1px solid $color-calendar-divisor;
      text-align: center;
      line-height: 25px;
    }
  }

  .schedules {
    display: flex;
    width: 100%;
    background-color: black;

    .team {
      width: 100%;
    }
  }
  .title {
    background-color: white;
    height: $daily-calendar-title-column-height;
    border-bottom: 1px solid $color-calendar-divisor;
  }
}

.noTeams {
  width: 100%;
  background-color: white;
  padding-top: 300px;
  height: 100%;
  font-size: 30px;
  text-align: center;
}
