.divisionContent {
  padding-top: 15px;
  text-align: start;
  padding-left: 30px;
  padding-bottom: 18px;
  padding-right: 30px;
  width: 100%;

  .includeInQuote {
    height: 35px;
    line-height: 35px;
  }

  .description {
    width: 100%;
  }

  .checkboxContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 10px;
  }

  .rowItem {
    text-decoration: none;
    font-size: 18px;
    overflow-wrap: normal;
    white-space: pre-wrap;
    width: 100%;
  }

  .preset {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }

  .notes {
    width: 100%;
    margin-bottom: 10px;

    textarea {
      width: 100%;
      min-height: 150px;
      min-width: 140px;
    }
  }

  .subTypes {
    display: flex;
    flex-direction: row;
    min-height: 30px;
    width: 100%;
    margin-bottom: 10px;
  }

  .priceContainer {
    display: flex;
    justify-content: right;
    .price {
      width: 200px;
    }
  }

  .addBtnContainer {
    margin-top: 30px;
  }
}
