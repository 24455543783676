.container {
  padding: 20px;
  margin-top: 10px;

  .itemSelection {
    margin-bottom: 30px;
    max-width: 300px;
  }

  .data {
    border-top: 4px dashed black;
    padding-top: 40px;
    margin-top: 10px;

    .selectedItem {
      height: 50px;
      font-size: 24px;
      text-align: center;
      font-weight: bold;
    }

    .summaries {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      td {
        padding: 2px 10px;
        text-align: left;
      }
    }
  }
}
