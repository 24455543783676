.container {
  width: 100%;
  .toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .item {
      border-right: 2px solid black;
      border-top: 2px solid black;
      border-bottom: 2px solid black;
      height: 60px;
      line-height: 60px;
      text-align: center;
      flex: 1;
      font-weight: bold;

      &:first-child {
        border-left: 2px solid black;
      }

      &.selected {
        border-bottom: none;
      }
    }
  }
}
