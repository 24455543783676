.container {
  display: flex;
  flex-direction: row;
  --circle-size: 17px;
  gap: 1px;

  .filler {
    width: var(--circle-size);
    height: var(--circle-size);
    margin: auto;
    border-radius: var(--circle-size);
  }

  .eavestrough {
    width: var(--circle-size);
    height: var(--circle-size);
    background-color: #ff6600;
    margin: auto;
    border-radius: var(--circle-size);
  }
  .other {
    width: var(--circle-size);
    height: var(--circle-size);
    background-color: black;
    margin: auto;
    border-radius: var(--circle-size);
  }
  .window {
    width: var(--circle-size);
    height: var(--circle-size);
    background-color: blue;
    margin: auto;
    border-radius: var(--circle-size);
  }
  .windowQuote {
    width: var(--circle-size);
    height: var(--circle-size);
    background-color: transparent;
    margin: auto;
    border-radius: var(--circle-size);
    border: 3px solid blue;
    line-height: var(--circle-size);
    text-align: center;
    font-size: 3px;
  }
  .otherQuote {
    width: var(--circle-size);
    height: var(--circle-size);
    background-color: transparent;
    margin: auto;
    border-radius: var(--circle-size);
    border: 3px solid black;
    line-height: var(--circle-size);
    text-align: center;
    font-size: 10px;
  }
  .eavestroughQuote {
    width: var(--circle-size);
    height: var(--circle-size);
    background-color: transparent;
    margin: auto;
    border-radius: var(--circle-size);
    border: 3px solid orange;
    line-height: var(--circle-size);
    text-align: center;
  }
}
