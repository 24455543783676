.container {
  display: flex;
  flex-direction: row;

  .title {
    height: 20px;
    line-height: 20px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 21px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2196f3;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 17px;
    width: 17px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(39px);
    -ms-transform: translateX(39px);
    transform: translateX(39px);
  }

  /* Rounded sliders */
  .sliderRound {
    border-radius: 20px;
  }

  .sliderRound:before {
    border-radius: 50%;
  }
}
