@import 'scss/base/colors.scss';

.container {
  width: 100%;

  .details {
    display: flex;
    flex-direction: row;
  }

  .menuContainer {
    position: relative;
    width: 100%;

    .removeable {
      display: flex;
      flex-direction: row;

      div {
        margin-top: 4px;
      }
    }
    .menuTrigger {
      background: #ffffff;
      width: 100%;
      cursor: pointer;
      display: flex;
      padding: 6px 20px;
      border: 1px solid rgba(67, 66, 66, 0.5);
      border-radius: 20px;
      box-shadow: 2px 2px 8px -3px $color-main, -2px 0px 8px -3px $color-main;
      vertical-align: baseline;
      min-height: 35px;
      justify-content: center;

      span {
        text-align: center;
        font-size: 14px;
        margin: auto;

        &.hint {
          color: grey;
        }
      }

      .error {
        border-color: $color-error;
      }
    }

    .btnError {
      border: 1px solid red;
    }
  }
}

.errorMessage {
  font-size: 12px;
  margin-bottom: 3px;
  color: $color-error;
  min-height: 15px;
  margin-left: 15px;
}

.description {
  padding-left: 20px;
  margin: auto;
}

.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  z-index: 999;
  left: 0;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  max-height: 300px;
  overflow-y: scroll;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
