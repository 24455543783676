.container {
  min-width: 500px;

  .country {
    width: 100%;
    height: 50px;
    padding-top: 5px;
  }

  .verify {
    .btn {
      margin-top: 10px;
      width: 150px;
      margin-left: auto;
    }
  }

  .manual {
    border-top: 3px dashed black;
    margin-top: 20px;
    padding-top: 10px;

    .row {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      gap: 10px;

      .unit {
        width: 150px;
      }

      .number {
        width: 100px;
      }
    }
  }
}

.titleElement {
  width: 100%;
  margin-top: 30px;
  text-align: center;
  padding-top: 7px;
  font-weight: bold;
  border-top: 2px dashed black;
}

.existingClient {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding: 2px 10px;
  &:hover {
    background-color: #999999;
    border-radius: 20px;
  }
}
