.container {
  width: 100%;
  padding: 10px;

  .navigation {
    display: flex;
    flex-direction: row;
    height: 50px;

    .dateChangeBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;

      .dateChangeIcon {
        transform: scale(2);
      }
    }

    .date {
      flex: 1;
      line-height: 50px;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
    }
  }

  .jobNotStarted {
    border-color: green;
    background-color: rgba($color: green, $alpha: 0.05);
  }

  .jobInProgress {
    border-color: orange;
    background-color: rgba($color: orange, $alpha: 0.05);
  }

  .jobComplete {
    border-color: blue;
    background-color: rgba($color: blue, $alpha: 0.05);
  }

  .job {
    padding-left: 20px;
    padding-right: 20px;
    height: 40px;
    line-height: 40px;
    display: flex;
    flex-direction: row;
    border-width: 2px;
    border-style: solid;
    border-radius: 40px;
    margin-top: 5px;
    .time {
      padding-right: 10px;
    }

    .address {
      flex: 2;
      white-space: nowrap;
      overflow: hidden;
    }

    .types {
      flex: 1;
      display: flex;
      flex-direction: row;
      padding-bottom: 3px;
      --circle-size: 17px;

      .filler {
        width: var(--circle-size);
        height: var(--circle-size);
      }

      .eavestrough {
        width: var(--circle-size);
        height: var(--circle-size);
        background-color: orange;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 3px;
        border-radius: var(--circle-size);
      }
      .other {
        width: var(--circle-size);
        height: var(--circle-size);
        background-color: black;
        margin-top: auto;
        margin-bottom: auto;
        border-radius: var(--circle-size);
      }
      .window {
        width: var(--circle-size);
        height: var(--circle-size);
        background-color: blue;
        margin-top: auto;
        margin-bottom: auto;
        border-radius: var(--circle-size);
      }
      .windowQuote {
        width: var(--circle-size);
        height: var(--circle-size);
        background-color: transparent;
        margin-top: auto;
        margin-bottom: auto;
        border-radius: var(--circle-size);
        border: 3px solid blue;
        line-height: var(--circle-size);
        text-align: center;
        font-size: 3px;
      }
      .otherQuote {
        width: var(--circle-size);
        height: var(--circle-size);
        background-color: transparent;
        margin: auto;
        border-radius: var(--circle-size);
        border: 3px solid black;
        line-height: var(--circle-size);
        text-align: center;
        font-size: 10px;
      }
      .eavestroughQuote {
        width: var(--circle-size);
        height: var(--circle-size);
        background-color: transparent;
        margin-top: auto;
        margin-bottom: auto;
        border-radius: var(--circle-size);
        border: 3px solid orange;
        line-height: var(--circle-size);
        text-align: center;
      }
    }
    .price {
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 460px) {
  .container {
    .navigation {
      .dateChangeBtn {
        .dateChangeIcon {
          transform: scale(1.5);
        }
      }

      .date {
        font-size: 18px;
      }
    }

    .job {
      height: 35px;
      line-height: 35px;
      .time {
        font-size: 12px;
      }
      .address {
        font-size: 12px;
      }
    }
  }
}
