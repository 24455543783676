.description {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
  margin-bottom: 10px;

  .text {
    flex: 1;
  }

  .countdown {
    line-height: 35px;
    text-align: right;
    margin-left: 5px;
  }
}
