.container {
  width: 100%;

  th {
    padding: 5px;
    border-bottom: 3px solid black;
  }

  td {
    text-align: center;
  }

  .view {
    cursor: pointer;
    &:hover {
      background-color: grey;
      margin: 3px 10px;
      border-radius: 10px;
    }
  }

  .row {
    border-bottom: 1px solid black;
    height: 25px;
    line-height: 25px;

    .data {
      vertical-align: top;
      .hint {
        position: relative;
        cursor: pointer;
      }

      .docSection {
        margin-top: 10px;
        .subTitle {
          border-bottom: 1px solid black;
          text-align: center;
        }

        .documentName {
          display: flex;
          flex-direction: row;
          gap: 30px;
        }

        .shiftRight {
          margin-left: 50px;
        }
      }

      .hint::after {
        content: attr(title);
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: #333;
        color: #fff;
        padding: 5px;
        border-radius: 3px;
        opacity: 0;
        transition: opacity 0.1s ease;
        white-space: nowrap;
      }

      .hint:hover::after {
        opacity: 1;
      }
    }
    .date {
      width: 230px;
      text-align: center;
    }

    .center {
      text-align: center;
    }
  }

  .panel {
    width: 200px;
    height: 100%;
  }

  .clickable {
    &:hover {
      background-color: grey;
      border-radius: 10px;
      cursor: pointer;
      padding: 2px;
    }
  }
}
