.container {
  margin: 0 10px;
  .quotes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 4px;
    border-bottom: 1px dashed black;

    .info {
      flex: 1;

      .description {
        word-wrap: break-word;
      }

      .extras {
        span {
          float: right;
        }
      }
    }

    .icon {
      margin-right: auto;
    }
  }
  :last-child {
    padding-bottom: 4px;
    border-bottom: none;
  }
}
