@import 'scss/base/colors.scss';
@import 'scss/base/sizes.scss';

.team {
  width: 100%;
  min-width: 300px;
}

.title {
  text-align: center;
  border-bottom: 1px solid $color-calendar-divisor;
  vertical-align: center;
  height: $daily-calendar-title-column-height;
  line-height: $daily-calendar-title-column-height;
  font-size: 18px;
  background-color: #000000;
  position: relative;
  border-right: $daily-calendar-title-column-divisor solid white;
  color: #ffffff;
}
.body {
  width: 100%;
  position: relative;
}
