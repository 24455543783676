@import 'scss/base/colors.scss';
*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.selector {
  padding: 5px;
  color: black;
  width: 200px;
}

.errorMessage {
  font-size: 12px;
  margin-bottom: 3px;
  color: $color-error;
  min-height: 15px;
  margin-left: 15px;
}

.menuContainer {
  position: relative;
  width: 100%;
  min-width: 180px;

  .menuTrigger {
    background: #ffffff;
    width: 100%;
    cursor: pointer;
    display: flex;
    padding: 6px 20px;
    border: 1px solid rgba(67, 66, 66, 0.5);
    border-radius: 20px;
    box-shadow: 2px 2px 8px -3px $color-main, -2px 0px 8px -3px $color-main;
    vertical-align: baseline;
    min-height: 35px;
    justify-content: center;

    span {
      text-align: center;
      font-size: 14px;
      margin: auto;

      &.hint {
        color: grey;
      }
    }

    .error {
      border-color: $color-error;
    }
  }

  .btnError {
    border: 1px solid red;
  }
}

.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  z-index: 999;
  left: 0;
  width: 100%;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.time {
  display: flex;
  flex-direction: row;
  height: 200px;
  border: 3px solid black;
  width: 180px;
  justify-content: space-evenly;
  cursor: pointer;

  .item {
    border-bottom: 1px solid black;
    margin-block: 0px 2px;

    cursor: pointer;

    .shade {
      background-color: grey;
    }
  }

  .hours {
    flex: 1;
    overflow-y: scroll;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .minutes {
    flex: 1;
    line-height: 50px;
    text-align: center;
    overflow-y: scroll;
    border-left: 2px solid black;
    cursor: pointer;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .timeOfDay {
    border-left: 2px solid black;
    flex: 1;
    line-height: 50px;
    text-align: center;
    overflow-y: scroll;
    cursor: pointer;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .selected {
    background-color: red;
    border-radius: 50px;
    margin: 2px;
    cursor: pointer;
  }
}
