.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .panel {
    width: 200px;
    height: 100%;
  }

  .summaries {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    padding: 25px;

    .mainSummaries {
      flex: 1;
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
    }

    .smallSummaries {
      flex: 1;

      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }
}

th {
  padding: 5px;
  border-bottom: 3px solid black;
}

tr {
  border-bottom: 1px solid black;
  height: 30px;
  line-height: 30px;

  td {
    .hint {
      position: relative;
      cursor: pointer;
    }

    .hint::after {
      content: attr(title);
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: #333;
      color: #fff;
      padding: 5px;
      border-radius: 3px;
      opacity: 0;
      transition: opacity 0.1s ease;
      white-space: nowrap;
    }

    .hint:hover::after {
      opacity: 1;
    }
  }
  .date {
    width: 230px;
    text-align: center;
  }

  .center {
    text-align: center;
  }

  .clickable {
    &:hover {
      background-color: grey;
      border-radius: 10px;
      cursor: pointer;
    }
  }
}
