@import 'scss/base/colors.scss';

.menuContainer {
  position: relative;
}

.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  z-index: 999;
  left: 0;
  max-height: 400px;
  overflow-y: scroll;

  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 200px;

    .item {
      padding: 10px;
      &:hover {
        padding: 10px;
        background-color: grey;
      }
    }
  }
}

.menuTrigger {
  background: #ffffff;
  min-width: 350px;
  height: 10px;
  cursor: pointer;
  display: flex;
  padding: 0px 10px;
  border: 1px solid rgba(67, 66, 66, 0.5);
  border-radius: 20px;
  box-shadow: 2px 2px 8px -3px $color-main, -2px 0px 8px -3px $color-main;
  vertical-align: baseline;
  min-height: 36px;
  justify-content: center;

  span {
    text-align: center;
    font-size: 14px;
    margin: auto;
  }
}
