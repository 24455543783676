@import 'scss/base/colors.scss';
.container {
  position: relative;
  width: 100%;
  height: 100%;

  textarea,
  label {
    display: block;
    width: 100%;
    min-height: 100%;
  }

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .removeButton {
    position: absolute;
    bottom: 0;
    left: 87%;
    padding-bottom: 1px;
  }

  .info {
    border: 1px solid rgba(67, 66, 66, 0.5);
    border-radius: 20px;
    box-shadow: 2px 2px 8px -3px $color-main, -2px 0px 8px -3px $color-main;
    padding: 6px;
    background-size: 8%;
    padding-left: 15px;
    padding-right: 15px;
    height: 23px;

    ::placeholder {
      color: $color-hint;
      font-size: 8px;
    }
  }

  .label {
    font-size: 12px;
    margin-bottom: 3px;
    color: $color-hint;
    min-height: 15px;
    margin-left: 15px;
  }
  .errorMessage {
    font-size: 12px;
    margin-bottom: 3px;
    color: $color-error;
    min-height: 15px;
    margin-left: 15px;
  }
}
