.container {
  width: 1000px;
  display: flex;
  flex-direction: row;

  .details {
    min-width: 400px;
    text-align: center;
    margin-right: 10px;
    flex: 1;

    .table {
      margin: 20px 0px;
    }

    .mainDivision {
      background-color: black;
      color: white;
      position: relative;
      height: 50px;
      line-height: 30px;
      padding: 10px 0px;
      font-size: 25px;
      .arrowControl {
        position: absolute;
        top: 5;
        left: 0;
        width: 30px;
        height: 30px;
        line-height: 30px;
      }
    }

    .divisionContent {
      padding-top: 15px;
      text-align: start;
      padding-left: 30px;
      padding-bottom: 18px;
      padding-right: 30px;
      width: 100%;

      .checkboxContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .checkboxes {
        }
      }
      .addBtnContainer {
        display: flex;
        justify-content: right;

        .addBtn {
          width: 200px;
        }
      }

      .rowItem {
        text-decoration: none;
        font-size: 18px;
        overflow-wrap: normal;
        white-space: pre-wrap;
        width: 100%;
      }

      .notes {
        width: 100%;

        textarea {
          width: 100%;
          min-height: 150px;
          min-width: 140px;
        }
      }

      .titledRow {
        display: flex;
        flex-direction: row;
        min-height: 30px;
        width: 100%;

        .content {
          flex: 1;
        }

        .includeTaxCheckbox {
          margin-left: 30px;
        }
        .rowTitle {
          width: 120px;
          text-align: right;
          padding-right: 10px;
          font-weight: bold;
          font-size: 19px;
        }
      }
      .notesSummary {
        .rowTitle {
          width: 120px;
          text-align: left;
          padding-right: 10px;
          font-weight: bold;
          font-size: 19px;
        }
      }
    }
  }
}
