.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .toolbar {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    gap: 10px;
  }

  .calendar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    overflow: hidden;

    .dayOfWeek {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      width: 100%;
      min-height: 50px;
      overflow: auto;
      box-shadow: 0px 2px 8px -2px #000000;
      background-color: grey;
      margin-right: 30px;

      .item {
        border: 1px solid black;
        min-width: 100px;
        text-align: center;
        line-height: 50px;
      }
    }

    .dateGrid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      width: 100%;
      flex: 1 1 auto;
      min-height: 800px;
      overflow: scroll;

      padding-bottom: 80px;
      background-color: white;
    }
  }
}
