.container {
  table {
    width: 100%;
    th {
      border-bottom: 2px solid black;
      &:last-child {
        border-bottom: none;
      }
    }

    .firstColumn {
      width: 100px;
    }
  }
}
