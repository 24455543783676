.item {
  display: flex;
  flex-direction: 1;

  width: 100%;

  .field {
    line-height: 40px;
    font-weight: bold;
    font-size: 18px;
    width: 150px;
    text-align: end;
  }
  .edit {
    padding-top: 2px;
    margin-left: 10px;
    min-width: 300px;
    display: flex;
    flex-direction: row;
    line-height: 40px;
    gap: 10px;
  }

  .addressFields {
    margin-top: 5px;
    margin-left: 10px;
    min-width: 300px;
    display: flex;
    flex-direction: row;
    line-height: 40px;
    gap: 10px;
  }
  .buttons {
    margin-left: 20px;
    padding-top: 2px;
    width: 300px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: red;
  }
}
