@import 'scss/base/colors.scss';

.clientInfo {
  text-align: center;
  .client {
    font-size: 25px;
    font-weight: bold;
  }
  .address {
    font-size: 20px;
    font-weight: bold;
  }
}

.description {
  width: 500px;
}

.status {
  width: 500px;
}

.officeNotes {
  height: 120px;
}

.title {
  padding-left: 20px;
  padding-bottom: 3px;
}

.customDropdown {
  display: flex;
  gap: 30px;
  flex-direction: row;
  justify-content: space-between;
  .address {
    font-weight: bold;
  }
}

.errorMessage {
  font-size: 12px;
  margin-bottom: 3px;
  color: $color-error;
  min-height: 15px;
  margin-left: 15px;
}
