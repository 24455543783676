.item {
  border: 1px solid black;
  height: auto;
  min-width: 100px;
  padding: 0px 10px;

  background-color: white;
}

.dayContainer {
  padding-top: 3px;
  font-size: 17px;
  font-weight: bold;
  width: auto;
  margin-bottom: 5px;

  .day {
    background-color: green;
    max-width: 30px;
    border-radius: 30px;
    height: 30px;
    text-align: center;
    vertical-align: center;
    line-height: 30px;
    color: white;
    background-color: black;
  }
}
.currentDay {
  border: 2px solid black;
  background-color: rgba($color: green, $alpha: 0.3);
}

.nonMonth {
  background-color: #999999;
}
