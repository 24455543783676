.container {
  width: 100%;
  height: 100%;

  .title {
    padding: 10px;
    width: 100%;
    font-size: 50px;
    height: 70px;
    line-height: 70px;
    text-align: center;
  }

  .content {
    padding: 50px 10px;
    width: 100%;

    .template {
      width: 100%;
      height: 80%;
    }
  }

  img {
    width: 200px;
    height: 200px;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    margin-right: 15px;
    float: right;
    gap: 10px;
  }

  .section {
    .title {
      border-top: 3px dashed black;
      font-size: 30px;
      margin-top: 10px;
    }
  }
}
