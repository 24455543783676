.checkbox {
  display: flex;
  align-items: center;
  padding: 2px;

  .border {
    display: flex;
    border: 2px solid grey;
    border-radius: 4px;
    width: 15px;
    height: 15px;
    margin-right: 8px;
    justify-content: center;
  }
  .indicator {
    z-index: 1;
    border-radius: 2px;
    background: red;
    width: 10px;
    height: 10px;
    margin: auto;
    transform: scale(0);
    transition: transform 100ms;
  }

  .checked {
    transform: scale(1);
  }

  .label {
    top: 2px;
    color: grey;
  }
}
.disabled {
  pointer-events: none;
}
