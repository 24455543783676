@import 'scss/base/colors.scss';

.section {
  border-top: 3px dashed black;
  padding-top: 10px;

  margin-top: 10px;
  padding-bottom: 10px;

  .title {
    text-align: center;
    margin-bottom: 10px;
  }

  .textarea {
    height: 100px;
  }
}

.errorMessage {
  font-size: 12px;
  margin-bottom: 3px;
  color: $color-error;
  min-height: 15px;
  margin-left: 15px;
}
