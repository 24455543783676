@import 'scss/base/colors.scss';
@import 'scss/base/font.scss';

.container {
  background-color: $color-main;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    padding: 0 1rem;
  }

  .listContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 100%;
  }
}
