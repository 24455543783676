.container {
  height: 100%;
  width: 100%;
  padding-bottom: 50px;

  .details {
    overflow: scroll;
    height: 100%;

    .centered {
      margin: auto auto;
      min-width: 300px;
      max-width: 600px;

      .update {
        margin-top: 10px;
        border-top: 2px dashed black;
        padding-top: 20px;

        .checkboxContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          gap: 10px;
        }

        .priceContainer {
          display: flex;
          justify-content: right;

          .title {
            text-align: center;
            line-height: 30px;
          }
          .price {
            width: 150px;
          }
        }

        .notes {
          margin-top: 10px;
          textarea {
            width: 100%;
            min-height: 100px;
            min-width: 140px;
          }
        }

        .saveBtnContainer {
          float: right;
          padding-top: 10px;
        }
      }
    }
  }

  .finalButtons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-evenly;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    margin-bottom: 10px;
    border-top: 3px solid black;
  }
}
