.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .colourRow {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    .teamColour {
      margin-left: 10px;
      --teamColour: '';
      width: 60px;
      height: 13px;
      background-color: var(--teamColour);
      border-radius: 10px;
      border-color: var(--teamColour);
    }
  }

  .panel {
    width: 200px;
    height: 100%;
  }

  .summaries {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    padding: 25px;

    .mainSummaries {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .smallSummaries {
      flex: 1;

      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }
}

.jobs {
  display: flex;
  flex-direction: row;
  --circle-size: 17px;
  padding: 5px;

  .date {
    padding-right: 20px;
  }

  .work {
    display: flex;
    flex-direction: row;
    width: 140px;
    padding-left: 10px;
    justify-content: space-evenly;
    margin-left: auto;
  }
}

th {
  padding: 5px;
  border-bottom: 3px solid black;
}

tr {
  border-bottom: 1px solid black;
  height: 30px;
  line-height: 30px;

  td {
    width: auto;
    .hint {
      position: relative;
      cursor: pointer;
    }

    .hint::after {
      content: attr(title);
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: #333;
      color: #fff;
      padding: 5px;
      border-radius: 3px;
      opacity: 0;
      transition: opacity 0.1s ease;
      white-space: nowrap;
    }

    .hint:hover::after {
      opacity: 1;
    }
  }

  .date {
    text-align: center;
  }

  .copy {
    margin-left: 10px;
    font-size: 15px;
    margin-top: auto;
    text-align: center;
    cursor: pointer;
  }

  .default {
    margin-right: 10px;
    font-size: 15px;
    margin-top: auto;
    text-align: center;
  }

  .center {
    text-align: center;
  }

  .clickable {
    &:hover {
      background-color: grey;
      border-radius: 10px;
      cursor: pointer;
    }
  }
}
