@import 'scss/base/colors.scss';

.summary {
  --colorHex: $main-color;
  background-color: rgba(var(--colorHex), 1);
  border: 3px solid var(--colorHex);
  border-radius: 20px;
  margin: 1px;
  padding: 3px 10px;
  margin-bottom: 10px;

  font-weight: bold;

  .details {
    display: none;
    font-weight: none;
    font-size: 12px;
  }
  transition-delay: 2s;

  &:hover {
    .details {
      display: block;
    }
  }
}
