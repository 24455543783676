@import 'scss/base/colors.scss';

.background {
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  max-width: 100%;
  background-color: $color-transparent-black;
  top: 0;
  left: 0;
  flex-wrap: wrap;
  overflow: hidden;

  .container {
    top: 50%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: $color-background;
    white-space: nowrap;
    height: 300px;
    width: 400px;
    margin: auto;
    border: 5px $color-main solid;
    padding: 10px;

    display: flex;
    flex-direction: column;

    label {
      height: 50px;
      text-align: center;
      margin-top: 20px;
      font-size: 30px;
      font-weight: bold;
    }
    .titleDivider {
      height: 3px;
      background-color: black;
    }
    p {
      margin-top: 20px;
      word-break: break-all;
      white-space: normal;
    }

    .buttons {
      position: fixed;
      padding: 10px;
      width: 100%;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      .button {
      }
    }
  }
}
