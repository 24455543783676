@import 'scss/base/colors.scss';

.background {
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  max-width: 100%;
  background-color: $color-transparent-black;
  top: 0;
  left: 0;
  flex-wrap: wrap;
  overflow: hidden;

  .container {
    top: 50%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: $color-background;
    white-space: normal;
    word-wrap: break-word;
    height: 300px;
    width: 250px;
    margin: auto;
    border: 5px black solid;
    padding: 10px;

    display: flex;
    flex-direction: column;

    label {
      min-height: 50px;
      text-align: center;
      margin-top: 20px;
      font-size: 25px;
      font-weight: bold;
      word-wrap: normal;
      white-space: normal;
    }
    .titleDivider {
      height: 3px;
      background-color: black;
    }
    p {
      margin-top: 20px;
      word-wrap: normal;
      white-space: normal;
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 10px;
  width: 95%;

  margin-bottom: 10px;
  background-color: $color-background;
  z-index: 999;
  font-size: 20px;
  font-weight: bold;

  .confirmBtn {
  }
}

@media only screen and (max-width: 460px) {
  .background {
    .container {
      width: 280px;
    }
  }
}
