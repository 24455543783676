@import 'scss/base/font.scss';
@import 'scss/base/colors.scss';

.container {
  background-color: $color-main;
  align-items: center;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  min-height: 100px;
  position: relative;
}

.title {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  font-size: $page-title;
}

.nav {
}

@media only screen and (max-width: 460px) {
  .title {
    font-size: $small-page-title;
  }
}
