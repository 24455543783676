.container {
  width: 1000px;
  display: flex;
  flex-direction: row;

  .details {
    min-width: 400px;
    text-align: center;
    margin-right: 10px;
    flex: 1;
    .pContainer {
      width: 300px;

      .title {
        margin: 10px 0 5px 0;
      }

      .payment {
        display: flex;
        flex-direction: row;
        .type {
          width: 150px;
        }
        .amount {
          width: 150px;
        }
      }

      .error {
        color: red;
        font-size: 13px;
      }
    }
  }
}
