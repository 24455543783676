@import 'scss/base/colors.scss';

.button {
  min-width: 130px;
  background-color: $color-background;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 2px solid black;
  border-radius: 35px;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    background-color: $color-hint;
  }
}

.noBorder {
  min-width: 50px;
  background-color: none;

  height: 35px;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
  padding: 0 10px;
  border-radius: 35px;
  &:hover {
    background-color: $color-hint;
  }
}
