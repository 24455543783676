.container {
  display: flex;
  flex-direction: row;
}

.type {
  display: fixed;
  bottom: 0px;
}

.addButton {
  width: 30px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  margin: auto;
}
.removeButton {
  width: 30px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  margin: auto;
}
