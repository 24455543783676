@import 'scss/base/colors.scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  background-color: $color-main;

  .toolbar {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    gap: 10px;
  }
  .calendar {
    margin-top: 10px;

    overflow: hidden;
  }
}
