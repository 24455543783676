@import 'scss/base/colors.scss';

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.selector {
  padding: 5px;
  color: black;
  width: 200px;
}

.errorMessage {
  font-size: 12px;
  margin-bottom: 3px;
  color: $color-error;
  min-height: 15px;
  margin-left: 15px;
}

.dateSelector {
  display: flex;
  flex-direction: row;
  .dateChangeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;

    .dateChangeIcon {
      transform: scale(2);
    }
  }
}
