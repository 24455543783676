.description {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  height: 100%;
  justify-content: baseline;

  .text {
    flex: 1;
    height: 100%;
  }

  .countdown {
    margin-top: auto;
    text-align: left;
    margin-left: 5px;
    width: 50px;
  }
}
