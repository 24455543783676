.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  .navigation {
    width: 200px;
    background-color: grey;

    .item {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-bottom: 2px solid black;
    }

    .selected {
      background-color: white;
    }
  }

  .settings {
    flex: 1;
  }
}
