.container {
  height: 100%;
  width: 100%;
  position: relative;

  .details {
    margin: 0;
    position: absolute;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;

    .reason {
      margin-top: 20px;
      height: 50px;
      width: inherit;
    }

    .navigation {
      display: flex;
      flex-direction: row;
      height: 50px;

      .dateChangeBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;

        .dateChangeIcon {
          transform: scale(2);
        }
      }

      .date {
        flex: 1;
        line-height: 50px;
        text-align: center;
        font-size: 22px;
        font-weight: bold;
      }
    }

    .error {
      font-size: 13px;
      color: red;
    }

    .summary {
      margin-top: 20px;
      .title {
        text-align: center;
        font-weight: bold;
        border-bottom: 2px dashed black;
      }

      .item {
        margin-left: 10px;
        margin-top: 4px;
        .reason {
        }
      }
    }
  }

  .btn {
    margin-top: 20px;
  }
}
