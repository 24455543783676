.titleElement {
  width: 100%;
  margin-top: 30px;
  text-align: center;
  padding-top: 7px;
  font-weight: bold;
  border-top: 2px dashed black;
}
.checkbox {
  margin-top: 30px;
}

.addButton {
  vertical-align: baseline;
  margin-top: auto;
  width: 50px;
}
.removeButton {
  vertical-align: baseline;
  margin-top: auto;
}

.existingClient {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 2px 10px;
  &:hover {
    background-color: #999999;
    border-radius: 20px;
  }
  .name {
    min-width: 205px;
  }
  .phone {
    width: 115px;
  }
  .filler {
    width: 115px;
  }
}
